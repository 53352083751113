<template>
  <div>
    <b-row v-if="user_data.status" class="match-height">
        <b-col
          lg="6"
          md="12"
        >
          <b-card
              no-body
          >
            <b-card-header>
              <div class="mb-1" style="margin-top: 5px;">
                <b-img
                      :src="zoomLogo"
                      alt="zoomlogo"
                      width="80px"
                    />
              </div>
            </b-card-header>
            <b-card-body v-if="zoomIntStatus">
              <b-card-text class="mb-2">
                <strong style="color: #000">STATUS: </strong> <b-badge variant="success"> Connected </b-badge>
              </b-card-text>
              <b-card-text class="mb-2">
                <strong style="color: #000">USER: </strong> <b-badge variant="light-primary"> {{zoomIdentity}} </b-badge>
              </b-card-text>
              <b-button
                variant="secondary"
                @click="revokeAccess('zoom')"
                size="sm"
              >
                  Remove Access
              </b-button>
            </b-card-body>
            <b-card-body v-else>
                <b-card-text class="mb-2">
                  Click the button below to connect your Zoom account to Live by IPSTUDIO™️. An existing zoom account is required.
                </b-card-text>
                <b-button
                  variant="primary"
                  href="https://live.ipstudio.co/zoom-auth/"
                >
                  <feather-icon
                      size="16"
                      icon="LogInIcon"
                      class="mr-50"
                    />
                    Sign In with Zoom
                </b-button>
            </b-card-body>
          </b-card>
        </b-col>

        <b-col
            lg="6"
            sm="12"
            v-if="!user_data.parent_id"
          >
            <b-card
                no-body
              >
                <b-card-header>
                  <div>
                    <b-img
                          :src="marianaLogo"
                          alt="marianaLogo"
                          width="155px"
                        />
                  </div>
                </b-card-header>
                <b-card-body v-if="mtIntStatus">
                  <b-card-text class="mb-2">
                    <strong style="color: #000">STATUS: </strong> <b-badge variant="success"> Connected </b-badge>
                  </b-card-text>
                  <b-card-text class="mb-2">
                    <strong style="color: #000">USER: </strong> <b-badge variant="light-primary"> {{mtIdentity}} </b-badge>
                  </b-card-text>
                  <b-button
                    variant="secondary"
                    @click="revokeAccess('mariana')"
                    size="sm"
                  >
                      Remove Access
                  </b-button>
                </b-card-body>
                <b-card-body v-else>
                  <b-card-text>
                  Click <strong><a href="mailto:integrations@marianatek.com?cc=admin@ipstudio.co&subject=MT%20V4%20OAuth2.0%20for%20Live%20by%20IPSTUDIO%E2%84%A2&body=Hi%20MT%20Integrations%20Team%2C%20we%20approve%20OAuth2.0%20for%20Live%20by%20IPSTUDIO%E2%84%A2.%20%20Thank%20you%21">here</a></strong> to request permission to integrate Mariana Tek with Live by IPSTUDIO™️.
                  </b-card-text>
                  <!-- form -->
                  <b-form>
                    <b-row>
                      <b-col sm="6">
                        <b-form-group
                          label="Subdomain"
                          label-for="subdomain"
                          class="mb-2"
                        >
                          <b-form-input
                            id="subdomain"
                            type="text"
                            v-model="subdomain"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="12">
                        <b-button
                          variant="primary"
                          @click="this.saveSubdomain"
                        >
                          <feather-icon
                              size="16"
                              icon="LogInIcon"
                              class="mr-50"
                            />
                            Sign In with Mariana Tek
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-form>
                  <!--/ form -->
                </b-card-body>
              </b-card>
          </b-col>
      </b-row>
      <b-alert
        variant="danger"
        show
        v-else
      >
        <h4 class="alert-heading">
          Verify your email address
        </h4>
        <div class="alert-body">
          <span>Thanks for signin up! A verification mail has been sent to your email account. Please check your inbox to verify. Account that has not been verified will be deleted in 10 days.</span>
        </div>
      </b-alert>
  </div>
</template>

<script>
/* eslint-disable */
import {
  BCardText, BImg, BBadge, BCard, BAlert, BCardHeader, BCardBody, BRow, BCol, BFormGroup, BFormInput, BButton, BForm,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store/index'

export default {
  components: {
    BCardText,
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BImg,
    BAlert,
    BBadge,
  },
  data() {
    return {
      zoomLogoUrl: require('@/assets/images/logo/zoom-logo.svg'),
      marianaLogoUrl: require('@/assets/images/logo/mariana.png'),
      subdomain: '',
    }
  },
  computed: {
    zoomLogo() {
      return this.zoomLogoUrl
    },
    marianaLogo() {
      return this.marianaLogoUrl
    },
    user_data() {
      return this.$store.state.app.user_data
    },
    mtIntStatus() {
      if(this.$store.state.app.integrationstate && this.$store.state.app.integrationstate.mariana && this.$store.state.app.integrationstate.mariana.status == 'connected'){
        return true
      }
      else{
        return false
      }
    },
    zoomIntStatus() {
      if(this.$store.state.app.integrationstate && this.$store.state.app.integrationstate.zoom && this.$store.state.app.integrationstate.zoom.status == 'connected'){
        return true
      }
      else{
        return false
      }
    },
    zoomIdentity() {
      return this.$store.state.app.integrationstate.zoom.identity;
    },
    mtIdentity() {
      return this.$store.state.app.integrationstate.mariana.identity;
    },
  },
  async mounted() {
    await store.dispatch('app/getSubdomain')
    this.subdomain = store.state.app.subdomain
    var intState = store.state.app.integrationstate
    if(Object.keys(intState).length === 0){
      this.$store.dispatch('app/getIntegrationStatus')
    }
  },
  methods: {
    async saveSubdomain() {
      if(this.subdomain != ''){
        var subdomainJson = {subdomain: this.subdomain};
        const config = { meta_key: 'mariana', meta_value: JSON.stringify(subdomainJson) }
        await store.dispatch('app/saveUserMeta', config)
        await store.dispatch('app/getSubdomain')
        setTimeout(function(){
          if(store.state.app.subdomain){
            window.location.replace("https://live.ipstudio.co/mariana-auth/")
          }
        }, 1000)
      }else{
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Subdomain is required.',
            icon: 'AlertCircleIcon',
            variant: 'failed',
          }
        })
      }
    },
    revokeAccess(id) {
      var self = this
      const config = { meta_key: id, meta_value: JSON.stringify({}) }
      this.$store.dispatch('app/saveUserMeta', config)
      setTimeout(function(){
        self.$store.dispatch('app/getIntegrationStatus')
      }, 2000)
    },
  }
}
</script>

<style>

</style>
